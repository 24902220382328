import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  isRouteErrorResponse,
  useLoaderData,
  useRouteError,
} from '@remix-run/react'
import { withSentry } from '@sentry/remix'
import { QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { type LinksFunction, type LoaderFunctionArgs, type MetaFunction, json } from '@vercel/remix'
import { AppLoadingProgress } from '~/components/AppLoadingProgress'
import { TooltipProvider } from '~/components/ui'
import { Toaster } from '~/components/ui/toaster'
import { getUserAndProfile } from '~/services/auth.server'
import { queryClient } from '~/services/react-query'
import globalStyles from '~/styles/globals.css?url'

export const meta: MetaFunction = () => [
  { charSet: 'utf-8' },
  { name: 'viewport', content: 'width=device-width,initial-scale=1' },
  { title: 'ホゴスル' },
  {
    name: 'description',
    content:
      '化学防護手袋の薬品耐透過性を検索。化学物質名やCAS番号を入力することで耐透過性の高い手袋を一覧。調達前に安全性を確認できます。',
  },
]

export const links: LinksFunction = () => [{ rel: 'stylesheet', href: globalStyles }]

export const loader = async ({ request }: LoaderFunctionArgs) => {
  const { user, profile } = await getUserAndProfile(request)
  const env = {
    GA_TRACKING_ID: process.env.GA_TRACKING_ID ?? '',
    NODE_ENV: process.env.NODE_ENV ?? 'development',
  }
  return json({ user, profile, env })
}

export const ErrorBoundary = () => {
  const error = useRouteError()

  if (isRouteErrorResponse(error)) {
    return (
      <div>
        <h1>
          {error.status} {error.statusText}
        </h1>
        <p>{error.data}</p>
      </div>
    )
  }
  if (error instanceof Error) {
    return (
      <div>
        <h1>Error</h1>
        <p>{error.message}</p>
        <p>The stack trace is:</p>
        <pre>{error.stack}</pre>
      </div>
    )
  }
  return <div>Something went wrong</div>
}

function App() {
  const { env, user, profile } = useLoaderData<typeof loader>()

  return (
    <html lang="ja">
      <head>
        <Meta />
        <Links />
      </head>
      <body className="scroll-smooth">
        {env.NODE_ENV === 'production' && (
          <>
            <script async src={`https://www.googletagmanager.com/gtag/js?id=${env.GA_TRACKING_ID}`} />
            <script
              async
              id="gtag-init"
              // biome-ignore lint/security/noDangerouslySetInnerHtml: <explanation>
              dangerouslySetInnerHTML={{
                __html: `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());

                gtag('set', 'user_properties', {
                  profile_company: '${profile?.company ?? ''}',
                  profile_department: '${profile?.department ?? ''}',
                  profile_position: '${profile?.position ?? ''}',
                  profile_scale: '${profile?.scale ?? ''}',
                  profile_typeofbusiness: '${profile?.typeofbusiness ?? ''}'
                });
                gtag('config', '${env.GA_TRACKING_ID}', {
                  user_id: '${user?.id ?? ''}',
                  page_path: window.location.pathname,
                });
              `,
              }}
            />
          </>
        )}

        <QueryClientProvider client={queryClient}>
          <AppLoadingProgress />
          <TooltipProvider>
            <Outlet />
          </TooltipProvider>
          <Toaster />
          <ReactQueryDevtools />
        </QueryClientProvider>
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  )
}

export default withSentry(App)
